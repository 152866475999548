// 发布状态
const releaseStatus = [
  {
    value: 0,
    label: "待发布",
  },
  {
    value: 1,
    label: "已发布",
  },
];

// 是否点赞
const isLikeObj = [
  {
    value: 0,
    label: "否",
  },
  {
    value: 1,
    label: "是",
  },
];
// 是否评论
const isEvaluateObj = [
  {
    value: 0,
    label: "否",
  },
  {
    value: 1,
    label: "是",
  },
];
// 用户类别
const userType = [
  {
    value: "10",
    checked: false,
    label: "居民",
  },
  {
    value: "11",
    checked: false,
    label: "企业/商户",
  },
];

import { mapHelper } from "@/utils/common.js";
const { map: releaseStatusMap, setOps: releaseStatusOps } =
  mapHelper.setMap(releaseStatus);
const { map: isLikeMap, setOps: isLikeOps } = mapHelper.setMap(isLikeObj);
const { map: isEvaluateMap, setOps: isEvaluateOps } =
  mapHelper.setMap(isEvaluateObj);
const { map: userTypeMap, setOps: setUserTypeOps } = mapHelper.setMap(userType);

export {
  releaseStatus,
  releaseStatusMap,
  releaseStatusOps,
  isLikeObj,
  isLikeMap,
  isLikeOps,
  isEvaluateObj,
  isEvaluateMap,
  isEvaluateOps,
  userTypeMap,
  setUserTypeOps,
};
