// 增加邻里公约
const addNeighborhoodConventionUrl = `/gateway/hc-serve/manageapi/neighborhood/addNeighborhoodConvention`;
// 编辑邻里公约
const updateNeighborhoodConventionUrl = `/gateway/hc-serve/manageapi/neighborhood/updateNeighborhoodConvention`;
// 邻里公约列表
const neighborhoodConventionListUrl = `/gateway/hc-serve/manageapi/neighborhood/neighborhoodConventionList`;
// 邻里公约详情
const neighborhoodConventionUrl = `/gateway/hc-serve/manageapi/neighborhood/neighborhoodConvention`;
// 签署记录列表
const neighborhoodSignRecordListUrl = `/gateway/hc-serve/manageapi/neighborhood/neighborhoodSignRecordList`;
// 签署记录详情
const neighborhoodSignRecordUrl = `/gateway/hc-serve/manageapi/neighborhood/neighborhoodSignRecord`;
// 签署记录删除
const deleteNeighborhoodConventionUrl = `/gateway/hc-serve/manageapi/neighborhood/deleteNeighborhoodConvention`;
// 小区列表
const communityListUrl = `/gateway/hc-space/space/getSpaceTreeList`;
//获取建筑列表
const getBuildListURL = `/gateway/hc-space/space/getSpaceTreeList`;

export {
  neighborhoodSignRecordListUrl,
  neighborhoodConventionListUrl,
  updateNeighborhoodConventionUrl,
  addNeighborhoodConventionUrl,
  neighborhoodConventionUrl,
  neighborhoodSignRecordUrl,
  deleteNeighborhoodConventionUrl,
  communityListUrl,
  getBuildListURL,
};
